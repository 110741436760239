<template>
  <StreamPlayer :code="this.$route.params.code"></StreamPlayer>
</template>

<script>
// @ is an alias to /src
import 'vue-advanced-chat/dist/vue-advanced-chat.css';
import StreamPlayer from '@/components/StreamPlayer_new_alt';

export default {
  name: 'PresentationWatchPopout',
  components: {
    StreamPlayer,
  },
  data: () => {
    return {

    };
  },
  mounted() {
  },
};
</script>

<style scoped>

.o-wrapper{
  background-color:#303030;
  padding-top:-80px;
}

.o-input {
  background-color:#303030;
  border-color: #3b3b3b;
}

.message-bubble {
  padding: 10px 0px 10px 0px;
}

.message-bubble:nth-child(even) {
  background-color: #505050;
}

.message-bubble > * {
  padding-left: 10px;
  color: #fff6f6;
}


.panel-body {
  padding: 0px;
  overflow: scroll;
}

.panel-heading {
  background:linear-gradient(65deg, #91334d, #3b53d0);
  color: white !important;
}
</style>
